<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <i class="fas fa-cog"></i>
           <div class="title mr-5"><span>{{ $t('common.insurance') }}</span>:</div>
           <div class="title"><span>{{ $t('table.head.insureRate') }}</span></div>
           <input type="number" class="rate mr-5" v-model="totalControl.topInsureRate" @change="setRate(totalControl)" />
           <div v-if="siteInfo.partnerType!=='senior'" class="title"><span>{{ $t('searchArea.insYN') }}</span></div>
           <ui-toggle v-if="siteInfo.partnerType!=='senior'" :textType="'default'" :checked="totalControl.insureUseYn" @setYn="setTotalInsuredUseYn(totalControl)" />
         </div>
         <button class="btn-search" type="button" @click="saveGameInfo">{{ $t('button.save') }}</button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <tr>
            <th rowspan="2" width="15%">{{ $t('table.head.gameType') }}</th>
            <th rowspan="2" width="14%"  v-if="siteInfo.partnerType!=='senior'">{{ $t('table.head.insureRate') }} (%)</th>
            <!-- <th rowspan="2">보험베팅적용금액</th> -->
            <th rowspan="2" width="15%" v-if="siteInfo.partnerType!=='senior'">{{ $t('searchArea.insApplyYN') }}</th>
          </tr>
          <tbody>
            <template v-for="item in settingList" :key="item.vendorCode">
              <tr>
                <td>
                  <span>{{ item.vendorName }}</span>
                </td>
                <td v-if="siteInfo.partnerType!=='senior'">
                  <input class="rate" type="number" v-model="item.topInsureRate" @change="setRate(item)" />
                </td>
                <!-- <td>
                <input class="amount" type="text" v-model="item.insuredBetAmt" />
              </td> -->
                <td v-if="siteInfo.partnerType!=='senior'">
                  <ui-toggle :textType="'default'" :checked="item.insureUseYn" @setYn="setInsuredUseYn(item)" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import { gameSettingList,gameSettingSave } from "@/api/bet.js";
import { GAME_INFO_LIST } from "@/libs/constants";
import uiToggle from "@/components/ui/UiToggle";

export default {
  name: "SportSetting",
  components: {
    uiToggle
  },
  data: function () {
    return {
      settingList: [],
      defaultGame: [],
      siteInfo:{},
      siteData:{},
      totalControl: {
        minBetAmt: 0,
        maxBetAmt: 0,
        topInsureRate: 0,
        insureUseYn: false,
        compBetYn: false
      },
      baseCreditRate:"",
      boolItems:['insureUseYn','compBetYn']
    };
  },
  watch:{
    // 'totalControl.insureUseYn'(value){
    //   for(const item of this.settingList){
    //     item.insureUseYn = value;
    //   }
    // },
    // 'totalControl.compBetYn'(value){
    //   for(const item of this.settingList){
    //     item.compBetYn = value;
    //   }
    // },
    'totalControl.topInsureRate'(value){
      // console.log(value);
      for(const item of this.settingList){
        item.topInsureRate = value;
        item.myInsureRate = (100 - value).toFixed(2)
      }
    },
    settingList:{
      handler(settings){
        // console.log(settings);
        let iCnt = 0;
        let compCnt = 0;
        for(const item of settings){
          if(item.insureUseYn){
            iCnt++;
          }
          if(item.compBetYn){
            compCnt++
          }
        }
        if(settings.length===iCnt){
          this.totalControl.insureUseYn = true
        }else{
          this.totalControl.insureUseYn = false
        }
        if(settings.length===compCnt){
          this.totalControl.compBetYn = true
        }else{
          this.totalControl.compBetYn = false
        }
      },
      deep:true
    }
  },
  methods: {
    setItem(item,key,e){
      this.onlyNumber(e);
      item[key] = this.deleteCommas(e.target.value);
      // console.log(item[key])
    },
    amtTotalControl(e){
      // console.log(e);
      const target = e.target.id;
      const value = e.target.value;
      for(const item of this.settingList){
        item[target] = this.deleteCommas(value);
      }
    },
    async getSettingList() {
      const req = { gameType: "sport" };
      const res = await gameSettingList(req);
      if (res.resultCode === "0") {
        const list = res.data.list;
        const boolItems=['insureUseYn','compBetYn'];
        this.baseCreditRate = list[0].baseCreditRate;
        for(const item of list){
          item.diffCreditRate = Number(item.baseCreditRate) - Number(item.vendorCreditRate);
          for(const key of boolItems){
            if(item[key]==='Y'){
              item[key] = true;
            }else{
              item[key] = false;
            }
          }
        }
        this.settingList = res.data.list;
      }
    },
    setRate(item) {
      const value = item.topInsureRate;
      if (!value) {
        item.topInsureRate = "0";
        return;
      }
      if (value > 100) {
        item.topInsureRate = "100";
      } else {
        item.topInsureRate = Number(value).toFixed(2);
      }
    },
    setInsuredUseYn(item) {
      item.insureUseYn = !item.insureUseYn;
    },
    setGameUseYn(item){
      item.compBetYn = !item.compBetYn;
    },
    setTotalInsuredUseYn(item) {
      // console.log(item)
      item.insureUseYn = !item.insureUseYn;
      for (const _item of this.settingList) {
        _item.insureUseYn = item.insureUseYn;
      }
    },
    setTotalCompUseYn(item){
      item.compBetYn = !item.compBetYn;
      for (const _item of this.settingList) {
        _item.compBetYn = item.compBetYn;
      }
    },
    async saveGameInfo(){
      const req = {gameType:'sport'}
      const settingList = lodash.cloneDeep(this.settingList);
      for(const item of settingList){
        item.myInsureRate = (100 - item.topInsureRate).toFixed(2)
        for(const key of this.boolItems){
          if(item[key]){
            item[key] = 'Y'
          }else{
            item[key] = 'N'
          }
          if(key==='minBetAmt' || key==='maxBetAmt'){
            item[key] = this.deleteCommas(item[key]);
          }
        }
      }
      req.settingItems = settingList;
      const res = await gameSettingSave(req);
      if(res.resultCode==='0'){
        alert('설정 저장 완료');
        await this.getSettingList();
      }
    }
  },
  async created() {
    this.siteInfo = this.getSiteInfo();
    await this.getSettingList();
  }
};
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable {
  table-layout: fixed;
}
.mainTable td:last-child {
  width: 20%;
}
.noti {
  display:inline-block;
  margin-left: 5px;
  color: #ff7875;
  padding-top:5px;
  padding-bottom:5px;
  font-weight: 700;
}
input[type="text"].amount {
  width: 100%;
  text-align: right;
}
input[type="number"].rate {
  width: 80px;
  text-align: center;
}

.searchBar input {
  max-width: 120px;
  text-align: right;
}
.searchBar input.rate {
  max-width: 80px;
}
</style>
